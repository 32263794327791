export const DATA2 = [
  {
    id: 1,
    name: "Sophia Anderson",
    userName: "Anderson22",
    tag1: "backend",
    tag2: "frontend",
    details:
      " Query Flo made SQL learning engaging and effective. The diverse question sets and adaptive difficulty levels provided a personalized experience. I highly recommend it for anyone serious about mastering SQL",
    img: "https://blog.texasbar.com/files/2011/02/ConstanceMims1.jpg",
  },
  {
    id: 2,
    name: "Ryan Patel",
    userName: "Patel101",
    tag1: "machinelearning",
    tag2: "deeplearning",
    details:
      "Query Flo is not just a platform; it's a SQL fiesta! The community forum is a lively carnival of ideas, and the expert interviews are like firework displays of SQL wisdom. Join the party!",
    img: "https://i.pinimg.com/736x/ae/ec/c2/aeecc22a67dac7987a80ac0724658493.jpg",
    order: true,
  },
  {
    id: 3,
    name: "Jessica Miller",
    userName: "Miller33",
    tag1: "database",
    tag2: "algorithms",
    details:
      "Query Flo, my secret weapon for SQL triumph! The realistic simulations are like a dress rehearsal for success, and the questions are the stars of my SQL performance. It's a blockbuster for job seekers!",
    img: "https://xsgames.co/randomusers/assets/avatars/male/63.jpg",
  },
  {
    id: 4,
    name: "Emma Chen",
    userName: "QEmmaChen88",
    tag1: "webdevelopment",
    tag2: "mobiledevelopment",
    details:
      "Query Flo is like a friendly SQL wizard guiding me through the magical realm of databases. The quizzes are enchanting spells that make SQL learning feel like a magical journey. Truly spellbinding!",
    img: "https://blog.texasbar.com/files/2011/02/ConstanceMims1.jpg",
    order: true,
  },
];
