import React from 'react'

export default function FailPayment() {
  return (
    <div style={{display:'flex', textAlign:'center', justifyContent:'center'}}>
    <p>
    The payment is has been cancelled!
    </p>
    </div>  )
}
