import React, { useState, useEffect } from "react";
import { DbCard, TopBar } from "../components";
import DatabaseDetails from "./databaseDetails";
import axios from "axios";
import { ClipLoader } from "react-spinners";

const Database = ({ isSideMenuOpen, toggleSideMenu }) => {
  const isProduction = process.env.NODE_ENV === "production";
  const apiUrlGetExercises = isProduction
    ? process.env.REACT_APP_API_URL_GET_EXERCISES_PROD
    : process.env.REACT_APP_API_URL_GET_EXERCISES;

  const [loading, setLoading] = useState(true);  // New loading state
  const [showDb, setShowDb] = useState(false);
  const [databaseData, setDatabaseData] = useState([]);
  const [selectedData, setSelectedData] = useState(null);

  useEffect(() => {
    // Make GET request when the component mounts
    axios
      .get(apiUrlGetExercises)
      .then((response) => {
        // Handle successful response
        setDatabaseData(response.data);
        setLoading(false);  // Set loading to false when data is available
      })
      .catch((error) => {
        // Handle error
        console.error("Error fetching data:", error);
        setLoading(false);  // Set loading to false in case of an error
      });
  }, []);

  return (
    <>
      <div>
        <TopBar
          heading={"Database"}
          isSideMenuOpen={isSideMenuOpen}
          toggleSideMenu={toggleSideMenu}
          search={true}
        />
      </div>

      {showDb ? (
        <>
          <DatabaseDetails
            ExerciseID={selectedData.id}
            databaseName={selectedData.ExerciseName}
            databaseDescription={selectedData.exerciseDescription}
            difficultyLevel={selectedData.DifficultyLevel}
            backBtn={() => {
              setShowDb(false);
            }}
          />
        </>
      ) : (
        <div className="database_section">
          <div className="list_heading">
            <h1>List of Uploaded Databases</h1>
          </div>

          <div className="db_cards">
            {loading ? (
             <div style={{ display: "flex", justifyContent: "center", alignItems:"center" }}>
             <ClipLoader color="black" size={25} />
           </div>
            ) : (
              databaseData.map((data) => (
                <DbCard
                  key={data.id}  // Add a key to each DbCard
                  text={data.ExerciseName}
                  onClick={() => {
                    setShowDb(true);
                    setSelectedData(data);
                  }}
                />
              ))
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default Database;
