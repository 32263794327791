import React from "react";
import "../../style/main.scss";
import { Link } from "react-router-dom";
import Button from "./button";
import StrokeButton from "./strokeButton";
import { useLocation } from "react-router-dom";
import BarIcon from "../../assets/image/Bar_Icon.svg";
import logowhite from "../../assets/icons/Updated_Logo.png";
const Navbar = () => {
  const location = useLocation();

  //destructuring pathname from location
  const { pathname } = location;
  //Javascript split method to get the name of the path in array
  const splitLocation = pathname.split("/");
  return (
    <div>
      {/* <nav className="nav_bar">
        <div className="logo">SQL Bot</div>
        <div className="links">
          <ul className="list">
            <li className={splitLocation[1] === "" ? "active" : ""}>
              <Link to="/" className="text-white list_link">
                Home
              </Link>
            </li>
            <li className={splitLocation[1] === "sqlpad" ? "active" : ""}>
              <Link to="/sqlpad" className="text-white list_link">
                SQL Pad
              </Link>
            </li>
            <li className={splitLocation[1] === "contactus" ? "active" : ""}>
              <Link to="/contactus" className="text-white list_link">
                Contact Us
              </Link>
            </li>
          </ul>
          <div className="buttons">
            <StrokeButton text="Create Account" />
            <Button text="login" />
          </div>
        </div>
        <div className="navbar_toggle">
          <img src={BarIcon} alt="baricon" />
        </div>
      </nav> */}

      <nav class="navbar navbar-expand-lg navbar-dark p-0">
        <div class="container-fluid text-white nav_bar">
          <a class="navbar-brand text-white logo" href="/">
            <img src={logowhite} alt="" />
          </a>

          <button
            class="navbar-toggler shadow-none  "
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon text-white "></span>
          </button>
          <div
            class="collapse navbar-collapse center_nav"
            id="navbarSupportedContent"
          >
            {/* <ul class="navbar-nav me-auto mb-2 mb-lg-0 ">
              <li class="nav-item">
                <a
                  class="nav-link active text-white"
                  aria-current="page"
                  href="#"
                >
                  Home
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link text-white" href="#">
                  Link
                </a>
              </li>
            </ul> */}

            <div className="links collapse navbar-collapse">
              <ul className="list navbar-nav me-auto mb-2 mb-lg-0 ">
                {/* <li
                  className={`${
                    splitLocation[1] === "" ? "active" : ""
                  } nav-item`}
                >
                  <Link
                    to="/"
                    className="text-white list_link nav-link active text-white"
                  >
                    Home
                  </Link>
                </li>
                <li className={splitLocation[1] === "sqlpad" ? "active" : ""}>
                  <Link to="/" className="text-white nav-link  list_link">
                    SQL Pad
                  </Link>
                </li> */}
                {/* <li
                  className={splitLocation[1] === "contactus" ? "active" : ""}
                >
                  <Link to="/" className="text-white nav-link  list_link">
                    Contact Us
                  </Link>
                </li> */}
              </ul>
              {/* <div className="buttons">
                <StrokeButton text="Create Account" />
                <Button text="login" />
              </div> */}
            </div>

            <form class="d-flex buttons ms-5" role="search">
              <StrokeButton text="Create Account" route={"/signup"} />
              <Button text="login" route={"/login"} />
            </form>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
