import React from "react";

const Button = ({ text, onClick, active, logout }) => {
  return (
    <>
      <button
        className={`${
          active ? "custom_btn_active" : logout ? "logout_btn" : "custom_btn"
        }`}
        onClick={onClick}
      >
        {text}
      </button>
    </>
  );
};

export default Button;
