import Facebook from "./icons/Facebook2.png";
import Discord from "./icons/Discord2.png";
import Twitter from "./icons/Twitter2.png";
import Instagram from "./icons/Instagram2.png";
import Community_Img from "./image/home/Community.png";
import User from "./image/home/User.png";
import Tick from "./icons/Tick.png";
import Twitter_Mini from "./icons/Twitter_Mini.svg";
import Comment from "./icons/Commit.png";
import Share from "./icons/Share.png";
import Like from "./icons/Like.png";
import Download from "./icons/Download.png";
import Learn_img from "./image/home/Learn.png";
import Learn_Google from "./icons/Learn_Google_Icon.png";
import Amazon from "./icons/Amazon.png";
import Apple from "./icons/Apple.png";
import Meta from "./icons/Meta.png";
import IYA from "./icons/IYA.png";
import Microsoft from "./icons/Microsoft.png";
import SQL_Logo from "./icons/SQL_Logo.png";
import Python_Logo from "./icons/Python_Logo.png";
import GetStarted from "./image/home/GetStarted.png";
import Watch_Icon from "./icons/Watch.png";
import Currency_Icon from "./icons/Currency.png";
import Increase_Icon from "./icons/Increase_Icon.png";
import Help_RIght_Img from "./image/home/Help_RIght_Img.png";
import Help_Left_Img from "./image/home/Help_Left_Img.png";
import Premium from "./image/home/Premium3.png";
import Practice from "./image/home/Practice3.png";
import Queries from "./image/home/Queries3.png";
import PrepCoach from "./image/home/PrepCoach3.png";
import Aggregate from "./image/home/Aggregate3.png";
import Python from "./image/home/Python3.png";
import Benfit_Top_Right from "./image/home/Benifit_Top_Right.png";
import Benfit_Bottom_Left from "./image/home/Benifit_Bottom_Left.png";
import Practice_Top_Right from "./image/home/Practice_Top_Right.png";
import Account_Top_Right from "./image/home/Account_Top_Right.png";
import Account_Bottom_Right from "./image/home/Account_Bottom_Right.png";
import Account_Elips from "./image/home/Account_Elips.png";

export const GRAPHICS = {
  Facebook,
  User,
  Watch_Icon,
  Currency_Icon,
  Increase_Icon,
  Account_Elips,
  Tick,
  Like,
  Download,
  Twitter_Mini,
  Share,
  Comment,
  Discord,
  SQL_Logo,
  Twitter,
  Instagram,
  Community_Img,
  Learn_img,
  Learn_Google,
  Meta,
  Microsoft,
  IYA,
  Amazon,
  Apple,
  GetStarted,
  Python_Logo,
  Help_RIght_Img,
  Benfit_Top_Right,
  Benfit_Bottom_Left,
  Practice_Top_Right,
  Account_Bottom_Right,
  Account_Top_Right,

  Help_Left_Img,
  Premium,
  Practice,
  Queries,
  PrepCoach,
  Aggregate,
  Python,
};
