import { GRAPHICS } from "../assets";

export const SIDEBAR_BTN = [
  {
    id: 1,
    button: "Dashboard",
    img: GRAPHICS.Dashboard_White,
    activeImg: GRAPHICS.Dashboard_Blue,
  },

  {
    id: 2,

    button: "AI text to SQL",
    img: GRAPHICS.AI_White,
    activeImg: GRAPHICS.AI_Blue,
  },

  {
    id: 3,
    button: "Database",
    img: GRAPHICS.Db_White,
    activeImg: GRAPHICS.DB_Blue,
  },

  {
    id: 4,
    button: "Bookmarks",
    img: GRAPHICS.History_White,
    activeImg: GRAPHICS.History_Blue,
  },

  {
    id: 5,
    button: "Leader Board",
    img: GRAPHICS.Leader_White,
    activeImg: GRAPHICS.Leader_Blue,
  },

  {
    id: 6,
    button: "Subscription",
    img: GRAPHICS.Subscription_White,
    activeImg: GRAPHICS.Subscription_Blue,
  },

  {
    id: 7,
    button: "Discussion Forum",
    img: GRAPHICS.Discussion_White,
    activeImg: GRAPHICS.Discussion_Blue,
  },

  {
    id: 8,
    button: "User Level",
    img: GRAPHICS.Level_White,
    activeImg: GRAPHICS.Level_Blue,
  },

  {
    id: 9,
    button: "User Profile",
    img: GRAPHICS.Profile_White,
    activeImg: GRAPHICS.Profile_Blue,
  },

  {
    id: 10,
    button: "Contact Us",
    img: GRAPHICS.Contact_White,
    activeImg: GRAPHICS.Contact_Blue,
  },

  {
    id: 11,
    button: "Report Bugs",
    img: GRAPHICS.Contact_White,
    activeImg: GRAPHICS.Contact_Blue,
  },
];

export const WINNER_DATA = [
  {
    id: 1,
    serial_no: "01",
    name: "Desirae Vaccaro",
    icon: GRAPHICS.Crown,
    btn: GRAPHICS.Green,
  },

  {
    id: 2,
    serial_no: "02",
    name: "Cheyenne Lubin",
    icon: GRAPHICS.Silver_Crown,
    btn: GRAPHICS.Green,
  },

  {
    id: 3,
    serial_no: "03",
    name: "Carla Vaccaro",
    icon: GRAPHICS.Light_Gold_Crown,
    btn: GRAPHICS.Red,
  },

  {
    id: 4,
    serial_no: "04",
    name: "Emerson Carder",
    // icon: GRAPHICS.Crown,
    btn: GRAPHICS.Green,
  },

  {
    id: 1,
    serial_no: "01",
    name: "Haylie Dokidis",
    // icon: GRAPHICS.Crown,
    btn: GRAPHICS.Green,
  },

  {
    id: 5,
    serial_no: "05",
    name: "Randy Levin",
    // icon: GRAPHICS.Crown,
    btn: GRAPHICS.Red,
  },

  {
    id: 6,
    serial_no: "06",
    name: "Randy Levin",
    // icon: GRAPHICS.Crown,
    btn: GRAPHICS.Red,
  },

  {
    id: 7,
    serial_no: "07",
    name: "Kaiya Dokidis",
    // icon: GRAPHICS.Crown,
    btn: GRAPHICS.Green,
  },

  {
    id: 8,
    serial_no: "08",
    name: "Ahmad Septimus",
    // icon: GRAPHICS.Crown,
    btn: GRAPHICS.Green,
  },

  {
    id: 9,
    serial_no: "09",
    name: "Tiana Mango",
    // icon: GRAPHICS.Crown,
    btn: GRAPHICS.Green,
  },
  {
    id: 10,
    serial_no: "10",
    name: "Upgrade Now",
    // icon: GRAPHICS.Crown,
    btn: GRAPHICS.Green,
  },
];
