import React, { useState, useEffect } from "react";
import {
  AIText,
  History,
  Database,
  ContactUs,
  Dashboard,
  UserLevel,
  LeaderBoard,
  UserProfile,
  Subscription,
  DiscussionForum,
  ReportBugs,
} from "./content";
import { GRAPHICS } from "./assets";
import { SIDEBAR_BTN } from "./mock";
import { Icon } from "@iconify/react";

import { getUserDataForCurrentUser } from "../../firebase/firebase";

const UserDashboard = ({isAdmin}) => {
 
  const [activeTab, setActiveTab] = useState("Dashboard");

  const [isSideMenuOpen, setSideMenuOpen] = useState(false);

  // Function to toggle the side menu
  const toggleSideMenu = () => {
    setSideMenuOpen(!isSideMenuOpen);
  };
  return (
    <>
      <div className="User_Dashboard">
        <div
          className={`Sidebar ${isSideMenuOpen ? "Sidebar_show" : "Sidebar"}`}
        >
          <div className="logo">
            <img src={GRAPHICS.Logo} alt="" />

            <div className="cross">
              <Icon
                icon="basil:cross-solid"
                color="white"
                width="40"
                height="40"
                onClick={() => {
                  setSideMenuOpen(false);
                }}
              />
            </div>
          </div>

          <div className="buttons">
            {SIDEBAR_BTN.map((item) => {
              return (
                <>
                  <div
                    className={`${
                      activeTab === item.button
                        ? "active_container"
                        : "container"
                    }`}
                    onClick={() => {
                      setActiveTab(item.button);
                    }}
                    key={item.id}
                  >
                    <img
                      src={
                        activeTab === item.button ? item.activeImg : item.img
                      }
                      alt=""
                    />

                    <button
                      className={`${
                        activeTab === item.button ? "active_btn" : "btn"
                      } `}
                      onClick={toggleSideMenu}
                    >
                      {item.button}
                    </button>
                  </div>
                </>
              );
            })}
          </div>

          <div className="progress_bar">
            {/* <img src={GRAPHICS.Upgrade} alt="" className="upgrade" /> */}
            {/* <div className="upgrate_now">
              <div>
                <div className="upgrade_img">
                  <img src={GRAPHICS.Upgrade_Now} alt="upgrade now" />
                </div>
                <h1>Want to upgrade</h1>
              </div>

              <button>Upgrade now</button>
            </div> */}

            {/* <img src={GRAPHICS.Progress_Bar} alt="" /> */}
            {/* <div className="your_progress">
              <h1>Your progress</h1>
              <h2>75% Questions Solved: 90 of 103</h2>
              
              <input type="range" />
            </div> */}
          </div>
        </div>

        {/* Dashboard Content */}
        <div className="Content">
          {activeTab === "Dashboard" ? (
            <>
              <div className="Dashboard_Content">
                <Dashboard
                  isSideMenuOpen={isSideMenuOpen}
                  toggleSideMenu={toggleSideMenu}
                  activeTab={activeTab}
                  setActiveTab={setActiveTab}
                  isAdmin={isAdmin}
                />

              </div>
            </>
          ) : activeTab === "AI text to SQL" ? (
            <>
              <div className="AI_Content">
                <AIText
                isAdmin={isAdmin}
                  isSideMenuOpen={isSideMenuOpen}
                  toggleSideMenu={toggleSideMenu}
                />
              </div>
            </>
          ) : activeTab === "Database" ? (
            <>
              <div className="Database_Content">
                <Database
                  isSideMenuOpen={isSideMenuOpen}
                  toggleSideMenu={toggleSideMenu}
                />
              </div>
            </>
          ) : activeTab === "Bookmarks" ? (
            <>
              <div className="History_Content">
                <History
                  isSideMenuOpen={isSideMenuOpen}
                  toggleSideMenu={toggleSideMenu}
                />
              </div>
            </>
          ) : activeTab === "Leader Board" ? (
            <>
              <div className="LeaderBoard_Content">
                <LeaderBoard
                  isSideMenuOpen={isSideMenuOpen}
                  toggleSideMenu={toggleSideMenu}
                />
              </div>
            </>
          ) : activeTab === "Subscription" ? (
            <>
              <div className="Subscription_Content">
                <Subscription
                  isSideMenuOpen={isSideMenuOpen}
                  toggleSideMenu={toggleSideMenu}
                />
              </div>
            </>
          ) : activeTab === "Discussion Forum" ? (
            <>
              <div className="DiscussionForm_Content">
                <DiscussionForum
                  isSideMenuOpen={isSideMenuOpen}
                  toggleSideMenu={toggleSideMenu}
                />
              </div>
            </>
          ) : activeTab === "User Level" ? (
            <>
              <div className="UserLevel_Content">
                <UserLevel
                  isSideMenuOpen={isSideMenuOpen}
                  toggleSideMenu={toggleSideMenu}
                />
              </div>
            </>
          ) : activeTab === "User Profile" ? (
            <>
              <div className="UserProfile_Content">
                <UserProfile
                  isSideMenuOpen={isSideMenuOpen}
                  toggleSideMenu={toggleSideMenu}
                />
              </div>
            </>
          ) : activeTab === "Contact Us" ? (
            <>
              <div className="ContactUs_Content">
                <ContactUs
                  isSideMenuOpen={isSideMenuOpen}
                  toggleSideMenu={toggleSideMenu}
                />
              </div>
            </>
          ) : activeTab === "Report Bugs" ? (
            <>
              <div className="Report_Bugs">
                <ReportBugs
                  isSideMenuOpen={isSideMenuOpen}
                  toggleSideMenu={toggleSideMenu}
                />
              </div>
            </>
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
};

export default UserDashboard;
