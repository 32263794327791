import Red from "./icons/Red.png";
import Logo from "./icons/Updated_Logo.png";
import Green from "./icons/Green.png";
import Crown from "./icons/Crown.png";
import crown_silver from "./icons/crown_silver.png";
import crown_gold from "./icons/crown-gold.png";
import crown_light_gold from "./icons/crown_light_gold.png";
import Silver_Crown from "./icons/Silver_Crown.png";
import Light_Gold_Crown from "./icons/Light_gold.png";
import Progress_Indicater from "./images/Progress _Indicatior.png";
import Like_Gray from "./icons/like.png";
import Profile from "./icons/Profile.png";
import Upgrade from "./images/Upgrade.png";
import Green_progress from "./images/green_progrees.png";
import Winner1 from "./images/Winner1.png";
import Winner2 from "./images/Winner2.png";
import Winner3 from "./images/Winner3.png";
import Back_End from "./images/back_end.png";
import Quiz_Icon from "./icons/Quiz_icon.png";
import Comment_Gray from "./icons/comment.png";
import Level_Icon from "./icons/level_icon.png";
import Upgrade_Now from "./images/Upgrade_Now.png";
import Cone_Left from "./images/coneLeft.png";
import Cone_Right from "./images/coneRight.png";
import Share_Arrow from "./icons/shareArrow.png";
import AI_Blue from "./icons/sidebar/AI_Blue.png";
import Search_Icon from "./icons/Search_Icon.png";
import Breaking_Line from "./images/Breaking_Line.png";
import Medal_Gold from "./icons/medal_gold.png";
import Medal_Silver from "./icons/medal_silver.png";
import Recall_icon from "./icons/Recall_icon.png";
import Accuracy_Icon from "./icons/accuracy_icon.png";
import Medal_Bronze from "./icons/medal_bronze.png";
import DB_Blue from "./icons/sidebar/Db_Blue.png";
import Notification from "./icons/Notification.png";
import AI_White from "./icons/sidebar/AI_White.png";
import Db_White from "./icons/sidebar/Db_White.png";
import Progress_Bar from "./icons/Progress_Bar.png";
import Python_Basic from "./images/Python_Basics.png";
import Back_End_Btn from "./images/back_end_btn.png";
import Arrow_Up_Mini from "./icons/Arrow_Up_Mini.png";
import Winner_Banner from "./images/Winner_Banner.png";
import Level_Blue from "./icons/sidebar/Level_Blue.png";
import Level_Btn_Img from "./icons/level_btn_img.png";
import Level_Btn_Img2 from "./images/level_btn_img2.png";
import Strongest_WFH from "./images/Strongest_WFH.png";
import Card_Tick_Icon from "./icons/Card_Tick_Icon.png";
import Contact_Us_Img from "./images/Contact_Us_Img.png";
import Strongest_Learn from "./images/Learn_Python.png";
import Discussion_Img from "./images/Discussion_Img.png";
import Arrow_Down_Mini from "./icons/Arrow_Down_Mini.png";
import Contact_Us_Img2 from "./images/Contact_Us_Img2.png";
import Level_White from "./icons/sidebar/Level_White.png";
import Leader_Blue from "./icons/sidebar/Leader_Blue.png";
import Strongest_Cyber from "./images/Strongest_Cyber.png";
import Profile_Blue from "./icons/sidebar/Profile_Blue.png";
import History_Blue from "./icons/sidebar/History_Blue.png";
import Leader_White from "./icons/sidebar/Leader_White.png";
import Strongest_Python from "./images/Strongest_Python.png";
import Contact_Blue from "./icons/sidebar/Contact_Blue.png";
import Profile_White from "./icons/sidebar/Profile_White.png";
import Leader_board_icon from "./icons/Leader_Board_Icon.png";
import Certification_Sql from "./images/Certification_Sql.png";
import Contact_White from "./icons/sidebar/Contact_White.png";
import History_White from "./icons/sidebar/History_White.png";
import User_Profile_Image from "./images/userProfileImage.png";
import Dashboard_Blue from "./icons/sidebar/Dashboard_Blue.png";
import Notification_Active from "./icons/Notification_Active.png";
import Discussion_Blue from "./icons/sidebar/Discussion_Blue.png";
import Dashboard_White from "./icons/sidebar/Dashboard_White.png";
import Certification_Learn from "./images/Certification_Learn.png";
import Discussion_White from "./icons/sidebar/Discusson_White.png";
import User_Profile_Img from "./images/User_level_Profile_Img.png";
import Subscription_Blue from "./icons/sidebar/Subscripton_Blue.png";
import Subscription_White from "./icons/sidebar/Subscription_White.png";
import Strongest_Python_Basic from "./images/Strongest_Python_Basics.png";

export const GRAPHICS = {
  Red,
  Logo,
  Crown,
  Green,
  Profile,
  Upgrade,
  Silver_Crown,
  Upgrade_Now,
  Green_progress,
  Light_Gold_Crown,
  Python_Basic,
  crown_gold,
  crown_light_gold,
  Leader_board_icon,
  crown_silver,
  Progress_Indicater,
  Level_Btn_Img2,
  Recall_icon,
  Accuracy_Icon,
  Cone_Right,
  Cone_Left,
  Back_End,
  AI_Blue,
  DB_Blue,
  Winner1,
  Winner2,
  Winner3,
  Db_White,
  AI_White,
  Like_Gray,
  Back_End_Btn,
  Strongest_Cyber,
  Strongest_Learn,
  Certification_Sql,
  Certification_Learn,
  Strongest_Python,
  Strongest_Python_Basic,
  Strongest_WFH,
  Quiz_Icon,
  Level_Icon,
  Medal_Gold,
  Level_Blue,
  Medal_Silver,
  Breaking_Line,
  Medal_Bronze,
  Level_White,
  Leader_Blue,
  Search_Icon,
  Share_Arrow,
  Notification,
  Profile_Blue,
  History_Blue,
  Progress_Bar,
  Level_Btn_Img,
  Leader_White,
  Contact_Blue,
  Comment_Gray,
  Winner_Banner,
  Profile_White,
  Contact_White,
  Arrow_Up_Mini,
  History_White,
  Dashboard_Blue,
  Discussion_Img,
  Contact_Us_Img,
  Card_Tick_Icon,
  Contact_Us_Img2,
  Discussion_Blue,
  Dashboard_White,
  Arrow_Down_Mini,
  User_Profile_Img,
  Discussion_White,
  Subscription_Blue,
  User_Profile_Image,
  Subscription_White,
  Notification_Active,
};
