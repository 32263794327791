import axios from 'axios';
import React, {useState, useEffect} from 'react'
import { getUserProfile } from "../../../firebase/firebase";

export default function SuccessPayment() {

    const isProduction = process.env.NODE_ENV === "production";

    const apiUrlGetSession = isProduction
      ? process.env.REACT_APP_API_URL_GET_PAYMENT_SESSION_PROD
      : process.env. REACT_APP_API_URL_GET_PAYMENT_SESSION;

      const apiUrlUpdateSession = isProduction
      ? process.env.REACT_APP_API_URL_UPDATE_SESSION_PROD 
      : process.env.REACT_APP_API_URL_UPDATE_SESSION;


      const [userProfile, setUserProfile] = useState(null);
    
      useEffect(() => {
        const fetchData = async () => {
          try {
            const profile = await getUserProfile();
            setUserProfile(profile);
          } catch (error) {
            // Handle errors if necessary
            console.error("Error fetching user profile:", error);
          }
        };
    
        fetchData();
      }, []);
    const [sessionData, setSessionData]=useState([]);

    useEffect(()=>{
        let userId = "QgG0jzn6tBSXabLyFX4p3XTqrZs2";
     

            axios.get(`${apiUrlGetSession}?userId=${userId}`).then((res)=>{
                console.log(res.data);
                let result = res.data.sessionDetails;
                setSessionData(result);
                if(result.payment_status = "paid"){
                    axios.put(apiUrlUpdateSession,{
                        stripeSessionId: result.id
                    }).then((res)=>{
                        console.log(res);
                    }).catch((error)=>{
                        console.log("Error",error);
                    })
                   
                }
            })
        

    },[])


  return (
    <div style={{display:'flex', textAlign:'center', justifyContent:'center'}}>
      <p>
      The payment is successfull
      </p>
      </div>
  )
}
