import { GRAPHICS } from "../assets";
export const DATA = [
  {
    id: 1,
    heading: "Access Premium Courses",
    details:
      "Unlock a world of knowledge with Access Premium Courses. Dive into expertly curated content that simplifies complex concepts. From foundational principles to advanced techniques, elevate your learning experience and master SQL effortlessly.    ",
    img: GRAPHICS.Practice,
  },

  {
    id: 2,
    heading: "Practice SQL",
    details:
      " Sharpen your SQL skills through dynamic Practice Sessions. Dive into real-world scenarios, refine your queries, and gain confidence in handling diverse database challenges. Elevate your SQL proficiency with interactive practice! ",

    img: GRAPHICS.Premium,
    order: true,
  },
  {
    id: 3,
    heading: "Aggregate Functions:",
    details:
      "Explore the power of Aggregate Functions in SQL. From summing up data to finding averages and more, dive into a world of efficient data analysis. Uncover the versatility of SQL with Aggregate Functions.",
    img: GRAPHICS.Aggregate,
  },
  {
    id: 4,
    heading: "Queries and Subqueries:",
    details:
      " Master the art of data retrieval with Queries and Subqueries. Learn to construct powerful queries, extract precise information, and explore the depth of SQL. Elevate your database querying skills effortlessly!",
    img: GRAPHICS.Queries,
    order: true,
  },
];
