import React from "react";
import "./topbar.scss";

const Topbar = () => {
  return (
    <>
      <div className="home_topbar">
        <p>Save 20% off all plans with welcome20</p>
      </div>
    </>
  );
};

export default Topbar;
